import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        watFocus: {
            type: Object,
            default: function () { }
        }
    },
    methods: {
        open: function () {
            this.$router.push({ name: 'focusPreview', params: {
                    id: this.watFocus.identifier,
                    slotHash: this.watFocus.slot.slotHash
                } });
        }
    }
});
