import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
export default defineComponent({
    props: ['test'],
    setup: function () {
        var router = useRouter();
        var goToTest = function (id, type) {
            if (type == "FOCUS_TEST") {
                router.push({ name: 'focustest', params: { id: id } });
            }
            else
                router.push({ name: 'test.show', params: { id: id } });
        };
        return {
            goToTest: goToTest,
            moment: moment,
        };
    },
});
